import axios from '@/api/axios'
import { ReportModel } from './models/ReportModel'

class ReportsService {
  async getReports(): Promise<ReportModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/reports')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getReports', error.message, error.response?.data)
      }
      throw error
    }
  }

  async downloadReport(id: number, type: string): Promise<Blob> {
    try {
      const response = await axios.get(`api/b2b/v1/reports/${id}/${type}`, {
        responseType: 'blob',
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('downloadReport', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new ReportsService()
