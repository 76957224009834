var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b()},[(!_vm.loading)?_c('div',[_c('div',{class:_vm.b('label')},[_vm._v("Отчеты и документы")]),_c('table',{class:_vm.b('table')},[_vm._m(0),_c('tbody',_vm._l((_vm.reportList),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"width":"10%","title":item.id}},[_vm._v(_vm._s(item.id))]),_c('td',{attrs:{"width":"20%","title":item.created_at}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('td',{attrs:{"width":"40%","title":item.period}},[_vm._v(_vm._s(item.period))]),_c('td',{attrs:{"width":"auto","align":"right"}},[_c('DropDownButton',{scopedSlots:_vm._u([{key:"dropdown",fn:function(ref){
var hide = ref.hide;
return [_c('ul',{class:_vm.b('list')},[(item.report)?_c('li',{on:{"click":function () {
                        hide()
                        _vm.downloadReport(item.id, 'report')
                      }}},[_vm._v(" Скачать отчет ")]):_vm._e(),_c('li',{on:{"click":function () {
                        hide()
                        _vm.downloadReport(item.id, 'act')
                      }}},[_vm._v(" Скачать акт ")])])]}}],null,true)},[_c('BaseIcon',{attrs:{"icon-name":"file-download","width":18,"height":18}}),_c('span',[_vm._v("Скачать")])],1)],1)])}),0)]),_c('a',{ref:"download",class:_vm.b('download')})]):_vm._e(),_c('Modal',{attrs:{"type":"error","show-modal":_vm.showErrorModal,"bg-in-class":"animate__fadeIn","bg-out-class":"animate__fadeOut"},on:{"close":function($event){_vm.showErrorModal = false},"after-close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Ошибка")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v("Не удалось выполнить операцию")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]},proxy:true},{key:"buttons",fn:function(){return [_c('BaseButton',{attrs:{"large":true},on:{"click":function($event){_vm.showErrorModal = false}}},[_vm._v("Закрыть")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Дата создания")]),_c('th',[_vm._v("Период")]),_c('th')])])}]

export { render, staticRenderFns }