























import { Component, Vue, Ref } from 'vue-property-decorator'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

@Component({
  name: 'DropDownButton',
  components: {
    TippyComponent,
  },
})
export default class DropDownButton extends Vue {
  @Ref() readonly tippy!: typeof TippyComponent

  get active(): boolean {
    return this.tippy?.tip?.state?.isShown ?? false
  }

  hide(): void {
    this.tippy.tip.hide()
  }
}
